import React from 'react'
import Page from '../components/Page'
import SEO from '../components/seo'
import OTApp from '../components/OTApp'

const OTAppPage = () => (
  <Page>
    <SEO
      title="Opentrons App | Open-source Lab Automation from $5,000"
      description="Download the OT App for use with OT-2 robot. Lab automation should be affordable, easy to use, and open to customization. The more people that have access to powerful technology, the better."
    />
    <OTApp />
  </Page>
)

export default OTAppPage
