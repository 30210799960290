export const OT2_ROBOT_DATA = {
  robotName: 'OT-2 Robot',
  opentronsApp: 'Opentrons App',
  opentronsUse: 'for use with OT-2 robot',
  OSVersion: [
    'Windows 10 or later',
    'macOS 10.10 or later',
    'Ubuntu 12.04 or later',
  ],
  usb: 'USB 2.0',
  wifi: 'Wi-Fi (Optional)',
  imgSrc: require('./assets/OT2_app.jpg').default,
  downloadLinks: {
    windowsUrl:
      'https://s3.amazonaws.com/opentrons-app/builds/Opentrons-v6.2.1-win-b25794.exe',
    macUrl:
      'https://s3.amazonaws.com/opentrons-app/builds/Opentrons-v6.2.1-mac-b25794.dmg',
    linuxUrl:
      'https://s3.amazonaws.com/opentrons-app/builds/Opentrons-v6.2.1-linux-b25794.AppImage',
  },
}

export const BUILDROOT_LINK =
  'https://opentrons-buildroot-ci.s3.amazonaws.com/d6b8e737-c8fa-4bb2-a6f5-3705263ead70/opentrons-buildroot/ot2-system.zip'

export const OT1_ROBOT_DATA = {
  robotName: 'OT-One Robot',
  opentronsApp: 'OT-One App',
  opentronsUse: 'for use with OT-One robot',
  OSVersion: [
    'Windows 10 or later',
    'macOS 10.9 or later',
    'Ubuntu 12.04 or later',
  ],
  usb: 'USB 2.0',
  imgSrc: require('./assets/OT1_app.jpg').default,
  downloadLinks: {
    windowsUrl:
      'https://s3.amazonaws.com/ot-app-builds/win/opentrons-v2.5.2-win-2017-08-18_22-18-release-2.5.2-4e7393c.exe',
    macUrl:
      'https://s3.amazonaws.com/ot-app-builds/mac/opentrons-v2.5.2-mac-2017-08-18_22-40-release-2.5.2-4e7393c.dmg',
    linuxUrl:
      'https://s3.amazonaws.com/ot-app-builds/linux/opentrons-v2.5.2-linux-amd64-2017-08-18_22-17-release-2.5.2-4e7393c.deb',
  },
}

export const FEATURE_CARD_DATA = [
  {
    cardName: 'API Documentation',
    cardDescription:
      'The Opentrons API is a simple framework designed to make writing automated biology lab protocols easy. Check out the documentation and start writing your own protocols.',
    button: {
      children: 'Explore API',
      Component: 'a',
      linkOutProps: {
        url: 'https://docs.opentrons.com/',
        gtm: {
          action: 'click',
          category: 'b-app',
          label: 'api-docs-button',
        },
      },
    },
  },
  {
    cardName: 'Protocol Library',
    cardDescription:
      'A community generated repository of commonly used protocols, including PCR, transformations, dilutions, and gene editing. Download a protocol to run in the OT App.',
    button: {
      children: 'Explore Library',
      Component: 'a',
      linkOutProps: {
        url: 'https://protocols.opentrons.com',
        gtm: {
          action: 'click',
          category: 'b-app',
          label: 'protocol-library-button',
        },
      },
    },
  },
]
