// @flow
import * as React from 'react'
import RequirementList from './RequirementList'
import DownloadLinks from './DownloadLinks'
import {ContentHalf, LinkOut} from '../../ui-components'

import {BUILDROOT_LINK} from './app-data.js'

import * as styles from './OTApp.module.css'

import type {RequirementListProps} from './RequirementList'
import type {DownloadLinksProps} from './DownloadLinks'

export type AppInfoProps = RequirementListProps & {
  downloadLinks: DownloadLinksProps,
  installInfo?: boolean,
  imgSrc: string,
  opentronsApp: string,
  opentronsUse: string,
}

export default function AppInfo(props: AppInfoProps) {
  const {
    opentronsApp,
    opentronsUse,
    imgSrc,
    installInfo,
    robotName,
    OSVersion,
    usb,
    wifi,
    downloadLinks,
  } = props
  return (
    <>
      <ContentHalf className={styles.align_top}>
        <img src={imgSrc} alt="" />
      </ContentHalf>
      <ContentHalf>
        <h3 className={styles.h3}>{opentronsApp}</h3>
        <h5 className={styles.h5}>{opentronsUse}</h5>
        <div className={styles.download_info}>
          <ContentHalf>
            <RequirementList
              robotName={robotName}
              OSVersion={OSVersion}
              usb={usb}
              wifi={wifi}
            />
          </ContentHalf>
          <ContentHalf>
            <DownloadLinks {...downloadLinks} />
          </ContentHalf>
        </div>
        {installInfo && (
          <>
            <div className={styles.install_info}>
              <h4 className={styles.h4}>Installation Instructions</h4>
              <p className={styles.p}>
                Visit this{' '}
                <LinkOut
                  url="https://support.opentrons.com/ot-2-onboarding-get-started-with-your-ot-2/3-set-up-the-ot-2-app/a-download-the-ot-2-app"
                  gtm={{
                    action: 'click',
                    label: 'app-support-install-link',
                    category: 'b-app',
                  }}
                  className={styles.links}
                >
                  support article
                </LinkOut>{' '}
                for detailed installation instructions, or check our{' '}
                <LinkOut
                  url="https://support.opentrons.com/ot-2-onboarding-get-started-with-your-ot-2/3-set-up-the-ot-2-app/b-supported-operating-systems-for-ot-2-app"
                  gtm={{
                    action: 'click',
                    label: 'app-support-os-link',
                    category: 'b-app',
                  }}
                  className={styles.links}
                >
                  operating system requirements
                </LinkOut>
                .
              </p>
            </div>
            <div className={styles.install_info}>
              <h4 className={styles.h4}>Robot Operating System Update</h4>
              <p className={styles.p}>
                Robot Operating System updates require the computer running the
                Opentrons App to connect to the Internet. If that computer does
                not have access to the internet, you can download the latest
                Robot Operating System{' '}
                <a
                  className={styles.links}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={BUILDROOT_LINK}
                >
                  here
                </a>
                .
              </p>
              <br />
              <p className={styles.p}>
                View this{' '}
                <LinkOut
                  url="https://support.opentrons.com/en/articles/3272289-manual-software-update"
                  gtm={{
                    action: 'click',
                    label: 'app-support-manual-update-link',
                    category: 'b-app',
                  }}
                  className={styles.links}
                >
                  support article
                </LinkOut>{' '}
                for detailed manual robot software installation instructions.
              </p>
            </div>
          </>
        )}
      </ContentHalf>
    </>
  )
}
