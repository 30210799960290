// @flow

import * as React from 'react'
import {Button} from '../../ui-components'

import * as styles from './OTApp.module.css'

export type DownloadLinksProps = {
  macUrl: string,
  windowsUrl: string,
  linuxUrl: string,
}

export default function DownloadLinks(props: DownloadLinksProps) {
  const {windowsUrl, macUrl, linuxUrl} = props
  const LINK_OUT_DATA = [
    {
      name: 'mac',
      url: macUrl,
      gtm: {
        action: 'click',
        category: 'b-app',
        label: 'app-mac-button',
      },
    },
    {
      name: 'windows',
      url: windowsUrl,
      gtm: {
        action: 'click',
        category: 'b-app',
        label: 'app-wind-button',
      },
    },
    {
      name: 'linux',
      url: linuxUrl,
      gtm: {
        action: 'click',
        category: 'b-app',
        label: 'app-linux-button',
      },
    },
  ]
  return (
    <div>
      <h4 className={styles.h4}>Download</h4>
      {LINK_OUT_DATA.map((link, i) => {
        return (
          <Button
            key={i}
            outline
            Component="a"
            linkOutProps={link}
            className={styles.button}
          >
            {link.name}
          </Button>
        )
      })}
    </div>
  )
}
