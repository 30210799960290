// @flow
import * as React from 'react'

import * as styles from './OTApp.module.css'

export type RequirementListProps = {
  robotName: string,
  OSVersion: Array<string>,
  usb?: string,
  wifi?: string,
}

export default function RequirementList(props: RequirementListProps) {
  const {robotName, OSVersion, usb, wifi} = props
  return (
    <div>
      <h5 className={styles.requirements}>Requirements</h5>
      <div>
        <ul>
          <li className={styles.robot_spec_list}>
            <span className={styles.span_dot}>•</span>Opentrons {robotName}
          </li>
          <li className={styles.robot_spec_list}>
            <li className={styles.robot_spec_list}>
              <span className={styles.span_dot}>•</span>Operating System
            </li>
            <ul className={styles.os_list}>
              {OSVersion.map((version, i) => (
                <li className={styles.robot_spec_list} key={i}>
                  <span className={styles.span_dot}>•</span>
                  {version}
                </li>
              ))}
            </ul>
          </li>
          <li className={styles.robot_spec_list}>
            <span className={styles.span_dot}>•</span>
            {usb}
          </li>
          {wifi && (
            <li className={styles.robot_spec_list}>
              <span className={styles.span_dot}>•</span>
              {wifi}
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}
