// @flow

import * as React from 'react'
import AppInfo from './AppInfo'

import {FeatureCard} from '../../ui-components'

import * as styles from './OTApp.module.css'

import {OT2_ROBOT_DATA, OT1_ROBOT_DATA, FEATURE_CARD_DATA} from './app-data'

export default function OTApp() {
  return (
    <div className={styles.container}>
      <h2 className={styles.h2}>Get the Opentrons App</h2>
      <div className={styles.app_container}>
        <AppInfo {...OT2_ROBOT_DATA} installInfo={true} />
      </div>
      <div className={styles.app_container}>
        <AppInfo {...OT1_ROBOT_DATA} />
      </div>
      <div className={styles.feature_card_container}>
        {FEATURE_CARD_DATA.map((card, i) => {
          return <FeatureCard key={i} {...card} />
        })}
      </div>
    </div>
  )
}
