// extracted by mini-css-extract-plugin
export var h2 = "OTApp-module--h2--4vCN2";
export var h3 = "OTApp-module--h3--p2WHq";
export var h4 = "OTApp-module--h4--wdMBw";
export var h5 = "OTApp-module--h5--3t+YZ";
export var p = "OTApp-module--p--Bwr3v";
export var container = "OTApp-module--container--IPfcy";
export var links = "OTApp-module--links--gpxnz";
export var robot_spec_list = "OTApp-module--robot_spec_list--Dp0I-";
export var align_top = "OTApp-module--align_top--nYzPK";
export var requirements = "OTApp-module--requirements--rSSuK";
export var download_info = "OTApp-module--download_info--bNxCS";
export var button = "OTApp-module--button--r8UEy";
export var span_dot = "OTApp-module--span_dot--m6CSG";
export var app_container = "OTApp-module--app_container--cQOax";
export var os_list = "OTApp-module--os_list--6SgC4";
export var install_info = "OTApp-module--install_info--xMS55";
export var feature_card_container = "OTApp-module--feature_card_container--t-Hx-";